import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from '../shared/app.service';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit {
  public mainPageConfig:Observable<any>;
  constructor(
    public appService: AppService
  ) { }

  ngOnInit(): void {
    this.mainPageConfig = this.appService.getMainPageConfig();
  }

}
