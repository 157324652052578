import { HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit, HostListener, HostBinding, Input } from '@angular/core';


import { environment } from 'src/environments/environment'
import { AppService } from '../shared/app.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  @Input() UploadDest: string;

  @HostBinding('style.background-color') public background = '#fff';
  @HostBinding('style.opacity') public opacity = '1';

  public files: File[] = [];
  public seedFileName:string;
  progressInfos: any[] = [];
  message: string[] = [];
  private uploadFnUrl: string;
  constructor(private http: HttpClient, private appService: AppService) {
    this.uploadFnUrl = environment.upload_url;
  }

  //this is specifically for xml soe seed files
  ngOnInit(): void {

   }
public setSeedFile(fileName:string){
  this.seedFileName = fileName;
  if (this.seedFileName){
    this.http.get(this.seedFileName,{responseType:'text'}).subscribe(data=>{
      let date = new Date();
      let isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
      data = data.replace(/{{TIME}}/gm,isoDateTime);
      data = data.replace(/[\r\n]+/gm,'');
      data = data.replace(/[\s]{2,}/gm,'');
      let xmlf = new File([data],this.seedFileName.split('\\').pop().split('/').pop());
      this.files.push(xmlf);
    })
  }
}
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#9ecbec';
    this.opacity = '0.8'
  }
  //Dragleave listener, when something is dragged away from our host element
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#fff'
    this.opacity = '1'
  }

  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#f5fcff'
    this.opacity = '1'
    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      this.files.push(evt.dataTransfer.files[i]);
    }

  }


  uploadFiles() {
    console.log(this.UploadDest)

    // A little simple animation to clear out upload status messages
    const clearmsgs=(i)=>{
      if (i<this.message.length){
        setTimeout(() => {
          this.message.splice(i,1)
          clearmsgs(i++)
        }, 250);
      }
    }


    if (this.files.length > 0) {

      for (let i = 0; i < this.files.length; i++) {
        this.upload(i, this.files[i]);
        // if all files have been processed, clear out the message list
        if (i===(this.files.length-1)){
          setTimeout(() => {
            clearmsgs(0);
          }, 5000);
        }
      }

    }

  }
  removeFile(idx) {
    this.files.splice(idx, 1)
  }

  async upload(idx: number, file: File) {
    const formData: FormData = new FormData();

    let jwt = await this.appService.getJWTToken().toPromise();

    let headers = new HttpHeaders({
      "Authorization": `Bearer ${jwt}`
    });

    formData.append('s3dest', this.UploadDest);
    formData.append('file', file);
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    const req = new HttpRequest('POST', this.uploadFnUrl, formData, {
      headers,
      reportProgress: true,
      responseType: 'json'
    });

    this.http.request(req).subscribe((evt: any) => {
      if (evt.type === HttpEventType.UploadProgress) {
        this.progressInfos[idx].value = Math.round(100 * evt.loaded / evt.total);
      } else if (evt instanceof HttpResponse) {

        this.message.push(`Uploaded the file successfully: ${file.name}`);
      }
    },
      (err: any) => {
        this.progressInfos[idx].value = 0;
        this.message.push(`Could not upload the file: ${file.name}`);


      });

  }
}
