import {  Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { ReplaySubject, Observable } from 'rxjs';
export interface SideNavContent {
  logo: string;
  items: [];
  subItems: any[];
  expanded: false;
}

export interface MainPageItems {
  news_title: string;
  news_content: string;
  main_page_title: string;
  main_page_header: string;
  main_page_content: string;
  main_page_view: string;
}

@Injectable({
  providedIn: 'root'
})

export class AppService {

  public viewName = null;
  itemIcon = null;
  itemAlias = null;

  public toggled = true;
  private configURL = environment.config_url;
  public userID = null;
  public appName = null;
  public favIcon = null;
  public sideNavItems = [];
  public sideNavDropdownItems = [];
  public sideNavLogo = null;
  newsTitle = null;
  news = [];
  mainPageItemsTitle = null;
  mainPageItemContent = null;
  sideNavContent: SideNavContent[] = [];
  mainPageItems: MainPageItems;
  appConfig: any;
  private mainPageConfigObservable: ReplaySubject<any>;
  private appConfigObservable: ReplaySubject<any>;
  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthService,
    public sanitizer: DomSanitizer,
    private titleService: Title
  ) {

    this.mainPageItems = {
      main_page_content: '',
      main_page_header: '',
      main_page_title: '',
      main_page_view: '',
      news_content: '',
      news_title: ''
    }
    this.mainPageConfigObservable = new ReplaySubject();
    this.mainPageConfigObservable.next(this.mainPageItems);
    this.appConfigObservable = new ReplaySubject()
  }

  public appInit(userID) {
    this.userID = userID;

    this._httpClient.get(this.configURL).subscribe((config:any) => {
      this.appConfig = config["im-user"];
      this.appConfigObservable.next(this.appConfig);
      const mainPageItemContent = this.appConfig.main_page.content;
      this.mainPageItemContent = this.sanitizer.bypassSecurityTrustHtml(mainPageItemContent);

      this.mainPageItems = {
        news_title: this.appConfig.news.title,
        news_content: this.appConfig.news.content.join(' '),
        main_page_title: this.appConfig.main_page.title,
        main_page_header: this.appConfig.main_page.header_text,
        main_page_content: this.mainPageItemContent,
        main_page_view: this.appConfig.main_page.view
      };


      this.appName = this.appConfig.name;
      this.favIcon = this.appConfig.favicon;
      // this.newsTitle = config[this.userRole].news.title;
      // this.news = config[this.userRole].news.content;
      // this.mainPageItemsTitle = config[this.userRole].main_page.title;
      this.sideNavLogo = this.appConfig.logo;
      this.sideNavItems = this.appConfig.sidenav.menuItems;



      for (const SideNavitem of this.sideNavItems) {
        this.sideNavDropdownItems = SideNavitem.subItems;
        SideNavitem.expanded = false;
      }

      this.sideNavContent.push({
        logo: this.appConfig.logo,
        items: this.appConfig.sidenav.menuItems,
        subItems: this.sideNavDropdownItems,
        expanded: false
      });

      this.titleService.setTitle(this.appName);
      this.mainPageConfigObservable.next(this.mainPageItems);
    });


  }
  getMainPageConfig(): ReplaySubject<any> {

    return this.mainPageConfigObservable;

  }

  getViewURL(alias): string {
    return this.appConfig.views[alias]
  }

  getAppConfig(): ReplaySubject<any> {
    // this is a race condition when linking directly to a url
    return this.appConfigObservable;
  }

  getJWTToken(): Observable<string> {
    return new Observable(observer => {
      this._authService.getAccessTokenSilently({ authorizationParams: { audience: 'aep-im-viewer' } }).subscribe(tok => {
        observer.next(tok);
        observer.complete();
      }, (e) => {
        this._authService.getAccessTokenWithPopup({ authorizationParams: { audience: 'aep-im-viewer' } }).subscribe(tok => {
          observer.next(tok);
          observer.complete();
        });
      })
    })

  }

}
