import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { AppService } from '../shared/app.service';
import { Router } from '@angular/router';

export interface SideNav {
  name: [];
  icon: [];
  subname: [];
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  toggled = false;
  faUser = faUser;
  faPowerOff = faPowerOff;
  sideNavName = null


  constructor(
    public auth: AuthService,
    public appService: AppService,
    private router: Router,
    @Inject(DOCUMENT) public document: Document
  ) {
  }

  getShortName(fullName) {
    return fullName.substring(0, 2);
  }

  openDropdown(item) {
    item.expanded = !item.expanded;
    // this.status = !this.status;
  }
  openSubDropdown(subItem) {
    subItem.sub_expanded = !subItem.sub_expanded;
  }
  navigateView(item) {
    this.appService.viewName = item.name;
    this.appService.itemIcon = item.icon;
    this.router.navigate(['/views'], { queryParams: { item: item.alias } });

  }

  logout() {
    this.auth.logout(
      {logoutParams:{
        returnTo: window.location.origin,
        federated:true
      }
      })
  }

  toggleSideBar() {
    this.appService.toggled = !this.appService.toggled;
  }

  ngOnInit() { }


}
