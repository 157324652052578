<a id="show-sidebar" (click)="toggleSideBar()" class="btn btn-sm btn-dark">
  <i class="fas fa-angle-double-right"></i>
</a>
<nav id="sidebar" class="sidebar-wrapper">
  <div class="sidebar-content">
    <div class="sidebar-brand">
      <a href="#" id="home-btn"><img [src]="appService.sideNavLogo" alt=""></a>
      <div id="close-sidebar" (click)="toggleSideBar()">
        <i class="fas fa-angle-double-left"></i>
      </div>
    </div>
    <div class="sidebar-header" *ngIf="auth.user$ | async as user">
      <div class="user-pic">
        <div class="user-initials">{{getShortName(user.nickname)}}</div>
      </div>
      <div class="user-info" *ngIf="auth.user$ | async as user">
        <span class="user-name"> {{ user.nickname }}
        </span>
      </div>
    </div>
    <div class="sidebar-menu">
      <ul>
        <li class="sidebar-dropdown" id="home-side-btn">
          <a href="#">
            <i class="fa fa-home"></i>
            <span>Home</span>
          </a>
        </li>
        <div *ngFor="let sideNavItems of appService.sideNavContent">
          <li class="sidebar-dropdown" [ngClass]="item.expanded ? 'active' : 'not-active'"
            *ngFor="let item of sideNavItems.items">
            <a (click)="openDropdown(item)">
              <span>
                <i [ngClass]="item.icon" *ngIf="item.icon.includes('fa')"></i>
                <img [src]="item.icon" *ngIf="item.icon.includes('png')">
              </span>

              <span>{{item.name}}</span>
            </a>
            <div class="sidebar-submenu" [ngClass]="item.expanded ? 'sub-active' : 'not-active'"
              *ngFor="let subItem of item.subItems">
              <ul>
                <li *ngIf="subItem.dropdown == null">
                  <!-- <a [routerLink]="['/views']"  [queryParams]="{item:subItem.alias}"><i [ngClass]="appService.itemIcon" *ngIf="subItem.icon"></i> {{subItem.name}}</a> -->
                  <a (click)="navigateView(subItem)"><i [ngClass]="subItem.icon" *ngIf="subItem.icon"></i> {{subItem.name}}</a>
                </li>
                <li class="sidebar-dropdropdown" *ngIf="subItem.dropdown !== null"
                  [ngClass]="subItem.sub_expanded ? 'sub-sub-active' : 'not-active'">
                  <a (click)="openSubDropdown(subItem)">{{subItem.name}}</a>
                  <div class="sidebar-subsubmenu" [ngClass]="subItem.sub_expanded ? 'sub-sub-active' : 'not-active'">
                    <ul>
                      <li *ngFor="let item of subItem.dropdown">
                        <!-- <a [routerLink]="['/views']" [queryParams]="{item: item.alias}">{{item.name}}</a> -->
                        <a (click)="navigateView(item)">{{item.name}}</a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </div>

        <li class="sidebar-dropdown" id="nav-logout">
          <a (click)="logout()">
            <i class="fa fa-sign-out-alt"></i>
            <span>Logout</span>
          </a>
        </li>
      </ul>
    </div>

  </div>
</nav>