import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {AppService} from 'src/app/shared/app.service';
@Component({
  selector: 'app-jumbo-tron',
  templateUrl: './jumbo-tron.component.html',
  styleUrls: ['./jumbo-tron.component.scss']
})
export class JumboTronComponent implements OnInit {
  public mainPageConfig:Observable<any>;
  constructor(public appService: AppService) { }

  ngOnInit(): void {

    this.mainPageConfig = this.appService.getMainPageConfig();
    this.mainPageConfig.subscribe(c=> console.log(c));
  }

}
