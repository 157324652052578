<div class="m-5">


  <div class="card uploader">
    <div class="card-body">
      <h2 class="card-title">File Upload</h2>
      <div class="shadow dropZone">Drop files here</div>

      <button class="btn btn-success btn-sm mt-3" [disabled]="!files.length" (click)="uploadFiles()">
        Upload
      </button>
      <ul class="list-group list-group-flush">
        <li *ngFor="let file of files; let idx=index" class="list-group-item"><span class="dismiss" role="button" aria-hidden="true"
            (click)="removeFile(idx)">&times;</span> {{ file.name }}</li>
      </ul>

      <div *ngFor="let progressInfo of progressInfos" class="mb-2">
        <span>{{ progressInfo.fileName }}</span>
        <div class="progress">
          <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
            attr.aria-valuenow="{{ progressInfo.value }}" aria-valuemin="0" aria-valuemax="100"
            [ngStyle]="{ width: progressInfo.value + '%' }">
            {{ progressInfo.value }}%
          </div>
        </div>
      </div>
      <div *ngIf="message.length" class="alert alert-secondary my-3" role="alert">
        <ul>
          <li *ngFor="let msg of message; let i = index">{{ msg }}</li>
        </ul>
      </div>
    </div>


  </div>
</div>