import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// Import the AuthService type from the SDK
import { AuthService } from '@auth0/auth0-angular';
import { AppService } from 'src/app/shared/app.service';

import { environment } from './../environments/environment';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  favIcon: HTMLLinkElement;

  public viewURL: SafeResourceUrl;
  public mainPageItems: Observable<any>;
  constructor(
    public auth: AuthService,
    private sanitizer: DomSanitizer,
    public appService: AppService,

    @Inject(DOCUMENT) public document: Document,
    public router: Router,
  ) {
    console.log(`Production? ${environment.production}`);
    this.favIcon = document.querySelector('#favIcon');

  }

  ngOnInit() {
    this.auth.isAuthenticated$.subscribe(authenticated => {
      if (authenticated) {
        this.auth.getAccessTokenSilently().subscribe(
          (token) => {
            this.auth.user$.subscribe(
              (profile) => {
                this.appService.appInit(profile.sub);
              }
            );
          }

        );
      }

    });
    this.auth.isAuthenticated$.subscribe(authenticated => {
      if (!authenticated) {
        this.auth.loginWithRedirect();
      }
      this.mainPageItems = this.appService.getMainPageConfig();
      this.mainPageItems.subscribe(config => {
        if (this.appService.favIcon) {
          this.favIcon.href = `${this.appService.favIcon}?cacheBuster=${Date.now()}`;
        }

        let viewLink = config.main_page_view;
        if (viewLink) {
          this.viewURL = this.sanitizer.bypassSecurityTrustResourceUrl(viewLink);
        }
      })

    });
  }


}
