
<div class="d-flex flex-column h-100">
  <div class="page-wrapper aep-theme"  [ngClass]="{'toggled': appService.toggled}">
    <router-outlet></router-outlet>
    <div class="d-flex flex-column h-100" *ngIf="router.url === '/'">
      <app-sidenav></app-sidenav>
      <div class="page-wrapper aep-theme" [ngClass]="{'toggled': appService.toggled}">
        <div  *ngIf="mainPageItems" class="page-content">
          <app-page-header></app-page-header>
          <app-jumbo-tron></app-jumbo-tron>
          <app-announcements></app-announcements>
          <div class="col-md-12">
            <div>
              <div class="widget-container" >
                <div class="widget-title viz-title">{{(mainPageItems | async)?.main_page_title}}</div>
                <div class="widget-body">
                  <ul>
                    <li [innerHTML] = "(mainPageItems | async)?.main_page_content"></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
         <div class="col-md-12" *ngIf="viewURL">
          <iframe id="main_iframe" frameborder="0" allowtransparency="true" allowfullscreen="true" title="Data Visualization" marginheight="0" marginwidth="0" scrolling="no" style="display: block; width: 100%; height: 100vh;" [src]="viewURL">{{viewURL}}</iframe>
         </div>
        </div>
      </div>
    </div>
  </div>
</div>
