import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


// Import the module from the SDK
import { AuthModule } from '@auth0/auth0-angular';
import { SidenavComponent } from './sidenav/sidenav.component';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { ViewsComponent } from './views/views.component';
import { JumboTronComponent } from './jumbo-tron/jumbo-tron.component';
import { UploadComponent } from './upload/upload.component';


// Provide an initializer function that returns a Promise


@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    AnnouncementsComponent,
    PageHeaderComponent,
    ViewsComponent,
    JumboTronComponent,
    UploadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AuthModule.forRoot({
      "domain": "patrickco.auth0.com",
      "clientId": "V78Vq3zJ9X9fsc4VM6PgPPY0695y6pFF",
      "authorizationParams": {
        "redirect_uri": window.location.origin
      }
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {}
}
