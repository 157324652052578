<div class="col-md-12 widget-wrapper">
  <div class="">
      <div class="widget-container">
        <div class="widget-title">
          {{(mainPageConfig | async)?.news_title}}
        </div>
        <div class="widget-body">
          <ul>
              <li [innerHTML]="(mainPageConfig | async)?.news_content"></li>
          </ul>
        </div>
      </div>
  </div>
</div>
