import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewsComponent } from './views/views.component';
import {AuthGuard} from '@auth0/auth0-angular';

const routes: Routes = [
  {path: 'views', component: ViewsComponent, canActivate: [AuthGuard]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [ViewsComponent];
