<div class="d-flex flex-column h-100" >
  <app-sidenav></app-sidenav>

  <div class="page-wrapper aep-theme toggled">
    <div class="page-content">
      <app-page-header></app-page-header>

      <app-upload #appUpload *ngIf="uploadDest" [UploadDest]="uploadDest">

      </app-upload>

      <iframe *ngIf="viewURL"
      id="main_iframe"
      frameborder="0"
      allowtransparency="true"
      allowfullscreen="true"
      title="Data Visualization"
      marginheight="0"
      marginwidth="0"
      scrolling="no"
      [src]="viewURL">{{viewURL}}</iframe>

      <button  *ngIf="uploadDest && seedFileName" type="button" class="btn btn-sm btn-primary ml-5" (click)="seedSOEFile()">Create Manual DACR Entry</button>

    </div>
  </div>
</div>
