import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppService } from '../shared/app.service';
import { UploadComponent } from '../upload/upload.component';

@Component({
  selector: 'app-views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss']
})
export class ViewsComponent implements OnInit {
  @ViewChild('appUpload', { static: false }) appup: UploadComponent;

  private viewItem: any;
  public uploadDest: string;
  public seedFileName:string;
  public viewURL: SafeResourceUrl;
  constructor(
    public appService: AppService,
    private activatedRoute: ActivatedRoute,
    public sanitizer: DomSanitizer) { }
  async ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      this.appService.getAppConfig().subscribe(appConfig => {

        this.uploadDest = null;
        this.viewURL = null;

        this.viewItem = params['item'];
        let url = appConfig.views[this.viewItem]
        console.log(this.appService.viewName)
        if (url.startsWith('s3:')) {
          this.uploadDest = url.split('|')[0] || url;
          this.seedFileName = url.split('|')[1] || null;
        } else {
          url = `${url}?:embed=yes&:tabs=no&:toolbar=yes:refresh=yes`;
          this.viewURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
      });
    });
  }

  seedSOEFile(){
  this.appup.setSeedFile(this.seedFileName);
}

}
