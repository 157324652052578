import { Component, OnInit } from '@angular/core';
import { AppService } from '../shared/app.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  constructor(
    public appService: AppService,
    public router: Router
  ) { }

  ngOnInit(): void {}

}
